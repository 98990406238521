import { forwardRef, Link, LinkProps } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { TERMS_LINK } from "utils/config";

const TermsLink = forwardRef<LinkProps, "a">((props, ref) => (
  <Link
    ref={ref}
    href={TERMS_LINK}
    isExternal
    color="magenta.dark"
    _hover={{ color: "lightPink" }}
    {...props}
  >
    Terms and Conditions
    <ExternalLinkIcon ml={1} />
  </Link>
));

export default TermsLink;
