import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Collapse,
  Link,
  Text,
} from "@chakra-ui/react";
import NextLink from "next/link";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLogin } from "utils/auth";
import LoginLayout from "components/Layout/LoginLayout";
import Input from "components/Layout/LoginLayoutInput";
import HeadTitle from "components/Shared/HeadTitle";
import TermsLink from "components/Shared/TermsLink";
import createFormFieldComponents from "components/Shared/Forms/FormField";
import useCreateValidSubmitHandler from "components/Shared/Forms/useCreateValidSubmitHandler";
import Form from "components/Shared/Forms/Form";
import ContactEmailLink from "components/Shared/ContactEmailLink";
import RebrandModal from "components/Core/Auth/RebrandModal";

type FormFields = {
  email: string;
  password: string;
};

const schema: yup.ObjectSchema<FormFields> = yup.object().shape({
  email: yup.string().trim().email().required().label("Email"),
  password: yup.string().required().label("Password"),
});

const { FormField } = createFormFieldComponents<FormFields>({
  schema,
});

export default function Login() {
  const formContext = useForm<FormFields>({
    resolver: yupResolver(schema),
  });

  const { isValid } = formContext.formState;

  const [login, { error }, { isLoading, isLoggingIn }] = useLogin();

  const [handleValidSubmit] = useCreateValidSubmitHandler(
    (data: FormFields) => login({ variables: data }),
    { isSuccessToasted: false },
  );

  return (
    <Form {...{ formContext }} onValidSubmit={handleValidSubmit}>
      <HeadTitle title="Login" />
      <Collapse in={!!error}>
        <Alert status="error" variant="left-accent" mb={10}>
          <AlertIcon />
          <AlertDescription>{error?.message}</AlertDescription>
        </Alert>
      </Collapse>

      <FormField
        name="email"
        id="email"
        label={null}
        placeholder="Email"
        autoComplete="username"
        mt={12}
        renderInput={(props) => (
          <Input
            type="email"
            autoFocus
            size="lg"
            backgroundColor="white"
            {...props}
          />
        )}
      />

      <FormField
        name="password"
        id="current-password"
        mt={6}
        mb={0}
        label={null}
        placeholder="Password"
        autoComplete="current-password"
        renderInput={(props) => (
          <Input type="password" size="lg" backgroundColor="white" {...props} />
        )}
      />

      <Button
        type="submit"
        size="lg"
        colorScheme="pinkButton"
        mt={6}
        py={5}
        w="100%"
        aria-disabled={!isValid || isLoading}
        isLoading={isLoading}
        loadingText={isLoggingIn ? "Logging in" : "Submitting"}
      >
        Login
      </Button>

      <Box textAlign="center">
        <Text fontSize="xs" color="gray.500" mt={4}>
          By using this service you are agreeing to the <TermsLink />
        </Text>

        <Box mt={8}>
          <NextLink href="/forgot-password" passHref legacyBehavior>
            <Link
              fontWeight={200}
              fontSize="sm"
              _hover={{ color: "magenta.dark" }}
            >
              Forgot Password?
            </Link>
          </NextLink>
        </Box>

        <Box fontSize="sm" color="gray.500" mt={2}>
          For help accessing your account, please contact <ContactEmailLink />.
        </Box>
      </Box>

      <RebrandModal />
    </Form>
  );
}

Login.getLayout = (page: React.ReactElement) => (
  <LoginLayout heading="Welcome Back" subheading="Please login to continue">
    {page}
  </LoginLayout>
);

Login.allowUnauthenticated = true;
Login.disallowAuthenticated = true;
